<template>
  <div class="search search-container">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container>
      <div class="mb-3 mb-sm-0">
        <h1 class="search-title">
          Risultati ricerca
        </h1>
        <strong class="text-body-1" v-if="count > 0">
          {{ count }} prodotti trovati per
          <span class="highlight">"{{ query }}"</span> per la fascia oraria e il
          punto vendita selezionati
        </strong>
      </div>
      <p class="subtitle-1" v-if="filter">in {{ filter }}</p>
      <p class="subtitle-2" v-if="realSearch">
        Avevi cercato
        <a :aria-label="realSearch" :title="realSearch" class="real_search">{{
          realSearch
        }}</a>
        ma non ha prodotto risultati.
      </p>
    </v-container>

    <v-container>
      <!-- <div> -->
      <ProductListGrid
        :query="query"
        :barcode="barcode"
        :parentCategoryId="parent_category_id"
        :key="key"
        mode="search"
        @productsCount="updateCount"
        @searchedText="updateRealSearch"
        hideFilterString="Main Term"
        :virtualScrollViewport="$vuetify.breakpoint.xsOnly"
        :virtualPageSize="$vuetify.breakpoint.xsOnly ? 4 : 1"
      />
    </v-container>

    <category-block
      v-if="count == 0"
      :target="category"
      position="noresult"
      class="category-block category-block-1"
    />

    <category-block
      :key="category.categoryId"
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.search-container {
  .real_search {
    cursor: text;
  }
}
</style>
<script>
import ProductListGrid from "@/components/product/ProductListGrid.vue";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "SearchProducts",
  // props: ["query", "parent_category_id"],
  components: {
    ProductListGrid
    // ,
    // CategoryBlock
  },
  data() {
    return {
      key: 0,
      count: -1,
      query: null,
      barcode: null,
      parent_category_id: null,
      realSearch: null
    };
  },
  mixins: [categoryMixins, deliveryReactive],
  computed: {
    ...mapGetters({
      promoFilter: "category/promoFilter"
    })
  },
  methods: {
    ...mapActions({
      setCategory: "category/setCategory",
      resetFilters: "category/resetFilters"
    }),
    capitalizeFirstLetter(string) {
      return string && string.length
        ? string.charAt(0).toUpperCase() + string.slice(1)
        : "";
    },
    updateCount(count) {
      this.count = count;
    },
    updateRealSearch(searchedText) {
      this.realSearch = this.query;
      this.query = searchedText;
    },
    getQueryParams() {
      this.query = this.$route.query.q;
      this.barcode = this.$route.query.barcode;
      // this.queryLabel = this.$route.query.barcode
      //   ? this.$route.query.barcode
      //   : this.$route.query.q;
      this.parent_category_id = this.$route.query.parent_category_id;
      this.filter = this.$route.query.filter;
    },
    reload() {
      // this.$store.dispatch("category/resetFilters");
      this.key = this.key + 1;
    }
  },
  async created() {
    this.getQueryParams();
    // await this.resetFilters("q=" + this.query);
  },
  watch: {
    async "$route.query"() {
      this.getQueryParams();
      this.key = this.key + 1;
      this.realSearch = null;
      this.count = null;
      //await this.resetFilters("q=" + this.query);
      await this.setCategory({
        slug: "search",
        query: this.query,
        force: true
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    global.EventBus.$emit("clearSearch");
    next();
  }
};
</script>
